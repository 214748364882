<template>
  <div>
    <openAppointments :filter="filter" class="flix-form-group" />
    <welcomeScreen />
    <router-link
      v-if="Object.values($store.getters.bookings).length"
      class="flix-btn flix-btn-block flix-btn-info"
      :to="getLink()"
      ><flixIcon :id="'calendar'" />
      {{
        $t('message.goTo', { name: $tc('message.calendar', 2) })
      }}</router-link
    >
    <nextAppointments :filter="filter" class="flix-form-group" />
    <deletion v-if="!$route.name.includes('shares')" />
    <div class="flix-container-fluid">
      <div class="flix-row flix-form-group">
        <div class="flix-col-md-12" v-if="variables.state !== 'share'">
          <editBtn
            :onClick="
              () => {
                eventOverview()
              }
            "
          >
            <template v-slot:text>
              {{
                $tc('message.appointmentOverview', 2) +
                ' ' +
                $tc('message.eventTitle')
              }}
            </template>
          </editBtn>
        </div>
      </div>
    </div>
    <div class="flix-container-fluid">
      <div class="flix-row flix-form-group">
        <div class="flix-col-md-12" v-if="variables.state !== 'share'">
          <editBtn
            :onClick="
              () => {
                addCustomer()
              }
            "
          >
            <flixIcon slot="icon" class="icon" :id="'pen'" />
            <template v-slot:text>
              {{ $t('message.add', { name: $tc('message.appointments', 1) }) }}
            </template>
          </editBtn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filter: [String, Number]
  },
  data() {
    return {
      variables: {}
    }
  },
  components: {
    welcomeScreen() {
      return import('@/components/welcome/index')
    },
    nextAppointments() {
      return import('@/components/overview/nextAppointments')
    },
    openAppointments() {
      return import('@/components/overview/openAppointmentsAlert')
    },
    deletion() {
      return import('@/components/overview/deletion')
    }
  },
  methods: {
    settings() {
      this.$router.push({
        name: 'dashboardSettings'
      })
    },
    eventOverview() {
      this.$router.push({
        name: 'dashboardEventsBooking'
      })
    },
    addCustomer() {
      this.$router.push({
        name: 'dashboardAddCustomer',
        params: {
          id: 'all'
        }
      })
    },
    getLink() {
      var date = this.$store.getters.calendarDate
      if (!date) {
        date = new Date()
      }
      date =
        date.getFullYear() +
        '-' +
        this.$getNullsBefore(date.getMonth() + 1) +
        '-' +
        this.$getNullsBefore(date.getDate())
      var link = {
        name: 'bookingCalendarDate',
        params: { id: false, date: date }
      }
      if (this.variables.state === 'share') {
        link = {
          name: 'sharesBookingCalendarDate',
          params: { id: false, date: date }
        }
      }
      return link
    }
  },
  mounted() {
    this.variables = this.$getUserVariables()
  }
}
</script>
